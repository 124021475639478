<template>
  <section class="grey darken-4">
    <BannerHeader
      title="Contact"
      height="25vh"
      bgColor="black"
    />
    <v-container>
      <v-row justify="center" class="mt-8">
        <v-col cols="12" md="6" xl="5">
          <ContactForm />
        </v-col>
        <v-col
          cols="12"
          md="6"
          xl="4"
        >
          <TeamMembers />
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
  </section>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import ContactForm from '../components/ContactForm'
import TeamMembers from '../components/TeamMembers'

export default {
  metaInfo: {
    title: 'Contact',
    description: 'Contact C&C Industrial for Request for Proposals or questions about our solutions and concrete services for your current or next project.'
  },
  components: {
    BannerHeader,
    ContactForm,
    TeamMembers
  }
}
</script>

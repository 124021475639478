<template>
  <section>
    <v-card class="transparent" flat>
      <v-card-title class="text-h4 white--text">
        Team Members
      </v-card-title>
      <v-card-text>
        <v-divider class="primary" />
      </v-card-text>
      <v-fade-transition>
        <v-list
          v-if="staff.length"
          class="transparent"
        >
          <template v-for="(i, idx) in staff">
            <v-list-item
              :key="i.name"
              dense
            >
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2 grey--text text--lighten-2">{{ userFullName(i.firstName, i.lastName) }}</v-list-item-title>
                <v-list-item-subtitle class="grey--text text-caption">{{ i.title }}</v-list-item-subtitle>
              </v-list-item-content>
              <div class="text-subtitle-2 font-weight-regular grey--text text--lighten-2">
                {{ phoneNumber(i.phone) }}<span v-if="i.phoneExt"> Ext. {{ i.phoneExt }}</span>
              </div>
            </v-list-item>
            <v-divider
              v-if="idx < staff.length - 1"
              :key="`${idx}-divider`"
            />
          </template>
        </v-list>
      </v-fade-transition>
    </v-card>
  </section>
</template>

<script>
import { fs } from '@/firebase'
import { phoneNumber } from '@/global/filters/phoneNumber'
import { userFullName } from '@/core/utils'
export default {
  data () {
    return {
      staff: []
    }
  },
  methods: {
    phoneNumber,
    async getTeamMembers () {
      try {
        this.staff = []
        const res = await fs.collection('resources')
          .doc('team_members')
          .get()
        if (!res.exists) throw Error('No team members listed')
        this.staff = [...res.data().teamMembers]
      } catch (error) {
        this.$rollbar.error('Could not fetch Team Members from database', error)
      }
    },
    userFullName
  },
  mounted () {
    this.getTeamMembers()
  }
}
</script>

<template>
  <div>
    <section>
      <v-card
        class="transparent"
        flat
      >
        <v-card-title class="text-h4 white--text">
          Send Message
        </v-card-title>
        <v-card-text>
          <v-divider class="primary" />
        </v-card-text>
        <template v-if="!emailSent">
          <v-card-text>
            <v-form ref="contact_form">
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0"
                  md="6"
                >
                  <v-text-field
                    v-model="contactObj.firstName"
                    background-color="grey lighten-4"
                    class="rounded"
                    color="secondary"
                    dense
                    filled
                    hide-details
                    label="First Name"
                    required
                    :rules="required('First Name')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                  md="6"
                >
                  <v-text-field
                    v-model="contactObj.lastName"
                    background-color="grey lighten-4"
                    color="secondary"
                    class="rounded"
                    dense
                    filled
                    hide-details
                    label="Last Name"
                    required
                    :rules="required('Last Name')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="contactObj.company"
                    background-color="grey lighten-4"
                    color="secondary"
                    class="rounded"
                    dense
                    filled
                    hide-details
                    label="Company"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="contactObj.email"
                    background-color="grey lighten-4"
                    color="secondary"
                    class="rounded"
                    dense
                    filled
                    hide-details
                    label="Email"
                    required
                    :rules="emailRule()"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="contactObj.phone"
                    background-color="grey lighten-4"
                    color="secondary"
                    class="rounded"
                    dense
                    filled
                    hide-details
                    label="Phone"
                    required
                    :rules="required('Phone Number')"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="contactObj.department"
                    background-color="grey lighten-4"
                    color="secondary"
                    class="rounded"
                    dense
                    filled
                    hide-details
                    hint="What dept. should we direct your email to?"
                    :items="departments"
                    label="C&C Industrial Department"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-textarea
                    v-model="contactObj.message"
                    background-color="grey lighten-4"
                    color="secondary"
                    class="rounded"
                    dense
                    filled
                    hide-details
                    label="Message"
                    required
                    :rules="required('Message')"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pt-0 px-4">
            <v-spacer />
            <v-btn
              v-if="!emailSending"
              class="my-6"
              color="info"
              elevation="0"
              large
              @click="triggerEmailSend"
            >
              <span class="px-2">{{ emailSending ? 'Submitting Form...' : 'Submit' }}</span>
              <v-icon>
                fa-paper-plane
              </v-icon>
            </v-btn>
            <div
              v-if="emailSending"
              class="px-3 py-2 text-overline grey--text text--overline"
            >
              Submitting Form
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
          </v-card-actions>
        </template>
        <v-card-text>
          <v-alert
            v-if="emailSent"
            type="success"
            border="bottom"
            class="py-16"
          >
            Email has been sent
          </v-alert>
        </v-card-text>
      </v-card>
    </section>
  </div>
</template>

<script>
import { fs } from '@/firebase'
import firebase from 'firebase/app'

export default {
  data () {
    return {
      contactObj: {
        firstName: null,
        lastName: null,
        company: null,
        email: null,
        phone: null,
        department: null,
        message: null
      },
      emailSending: false,
      emailSent: false,
      departments: [
        'General',
        'Preconstruction',
        'Concrete Services',
        'Civil Division',
        'Concrete Washout Systems'
      ]
    }
  },
  methods: {
    required (fieldName) {
      return [
        v => (v !== null && v !== undefined && v !== '') || `${fieldName} is required`
      ]
    },
    emailRule () {
      return [
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ]
    },
    async triggerEmailSend () {
      if (!this.$refs.contact_form.validate()) {
        const invalidInput = this.$refs.contact_form.inputs.find(i => !i.valid)
        this.$vuetify.goTo(invalidInput, { offset: 100 })
        return
      }
      try {
        this.emailSending = true
        await fs.collection('contact_form').add({
          to: 'infocc@ccindustrial.com',
          from: 'infocc@ccindustrial.com',
          replyTo: this.contactObj.email,
          created_date: firebase.firestore.Timestamp.now(),
          template: {
            name: 'contact_form_template',
            data: {
              firstName: this.contactObj.firstName,
              lastName: this.contactObj.lastName,
              company: this.contactObj.company,
              email: this.contactObj.email,
              phone: this.contactObj.phone,
              message: this.contactObj.message
            }
          }
        })
        this.contactObj = {}
        this.emailSending = false
        this.emailSent = true
      } catch (error) {
        this.$rollbar.configure({
          payload: {
            component: 'ContactForm.vue',
            formData: this.contactObj
          }
        })
        this.$rollbar.error('Contact Form did not submit correctly', error)
        this.emailSending = false
        this.$store.commit('setSnackbar', {
          show: true,
          msg: 'There was an issue submitting your message. Please try again or give us a call at (252) 443-9403.',
          color: 'error',
          timeout: 3000
        })
      }
    }
  }
}
</script>
